@import "../../../variables.scss";

.batch-jobs {
  .table {
    th.queue {
      width: 35%;
    }

    th.users {
      width: 1%;
    }

    td.users {
      width: 1%;
      text-align: center;

      h6:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.share-batchEnvironment-modal {
  .share-pills-container {
    .share-pill {
      span {
        position: relative;
        top: -1px;
      }
      button {
        background: transparent !important;
        color: white;
        opacity: 0.8;
        border: 0;
        box-shadow: none;
        padding: 0;
        transition: opacity 0.15s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

code {
  font-family: Consolas, "courier new";
  color: rgb(78, 77, 77);
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
}
