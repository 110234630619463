@import "../../../variables.scss";

.data-storage {
  .table {
    th.customers,
    th.users {
      width: 1%;
    }
    td.customers,
    td.users {
      width: 1%;
      text-align: center;

      h6:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.share-bucket-modal {
  .share-pills-container {
    .share-pill {
      span {
        position: relative;
        top: -1px;
      }
      button {
        background: transparent !important;
        color: white;
        opacity: 0.8;
        border: 0;
        box-shadow: none;
        padding: 0;
        transition: opacity 0.15s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
