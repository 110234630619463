.users {
  .table {
    h4 {
      margin-bottom: 0;
    }

    td.actions {
      text-align: end;
    }
  }
}
