.sftp-info {
  .rounded-circle {
    color: white;
    display: flex !important;
    justify-content: center !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 1.3 !important;
    float: left;
    top: 1px;
    position: relative;
    padding: 0.5px;
  }
}

.copy-btn {
  height: 38px !important;
  line-height: 1 !important;
  font-size: 15px !important;
}
