.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.content-wrapper {
  margin-left: 270px;
  overflow: hidden;
  padding: 70px 12px 65px;
  min-height: 100vh;
  position: relative;

  &.sidebar-collapsed {
    margin-left: 70px;
  }
}
