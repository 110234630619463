.project-nodes {
  .table {
    .state-pill {
      i {
        margin-right: 3px;
        position: relative;
        top: 0.05rem;
      }
    }

    .state {
      width: 1%;
    }

    th.state {
      text-align: center;
    }
  }

  .table-sm {
    td.state {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}

.launch-instance-modal {
  .total-monthly-cost {
    border-top: 1px solid;
    margin-top: 2px;
    padding-top: 2px;
  }
}

.share-projectnode-modal {
  .share-pills-container {
    .share-pill {
      span {
        position: relative;
        top: -1px;
      }
      button {
        background: transparent !important;
        color: white;
        opacity: 0.8;
        border: 0;
        box-shadow: none;
        padding: 0;
        transition: opacity 0.15s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
