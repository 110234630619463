@import "../../../variables";

#login {
  .background {
    background-image: url("../../../media/excelra_background.jpg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
  .card-container {
    max-width: 500px;
  }
  .card {
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border: none;

    .card-title {
      text-align: center;
      font-size: 2rem;
    }

    .icon {
      text-align: center;
    }

    .card-header {
      text-align: center;
      background-color: $primary;
      border: none;
      padding: 1rem 1.25rem;
    }

    .card-body {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
