.header {
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
  min-height: 70px;
  position: fixed;
  left: 270px;
  top: 0;
  right: 0;
  z-index: 1001;

  &.sidebar-collapsed {
    left: 70px;
  }

  .toggle-sidebar {
    color: #98a6ad;
    height: 70px;
    width: 70px;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    font-size: 30px;
    cursor: pointer;
    float: left;
    z-index: 1;
    position: relative;
    margin-left: -24px;

    &:hover i {
      color: #5a6166;
    }
  }
}
