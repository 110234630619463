@import "./variables";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Import typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.scss";

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  color: $primary;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: $background-color;
}

.btn:disabled {
  cursor: not-allowed;
}

.spinner-border-lg {
  width: 3rem;
  height: 3rem;
  border-width: 0.35em;
}

.text-muted {
  color: #98a6ad !important;
}

.form-group-plaintext .form-label {
  padding-top: calc(0.375rem + 0.2rem);
}

.table {
  color: #6c757d !important;
}

.clickable {
  cursor: pointer !important;
}

.word-break-all {
  word-break: break-all !important;
}

// Excelra branding
@import "./excelra_branding.scss";
