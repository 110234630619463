@import "../../_variables.scss";

.sidebar-wrapper {
  width: 270px;
  z-index: 10;
  position: fixed;
  bottom: 0;
  top: 0;
  padding-top: 70px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $primary;
  min-height: 100vh !important;

  &.sidebar-collapsed {
    width: 70px;

    .logo {
      width: 70px;

      .logo-small {
        display: block;
      }

      .logo-large {
        display: none;
      }
    }

    .side-nav-title {
      display: none;
    }

    .side-nav-link {
      padding: 15px 20px;
      font-size: 24px;
      text-align: center;

      .bi {
        margin-right: 0;
      }
    }
  }

  .sidebar-container {
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .sidebar-content {
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.sidebar-wrapper .logo {
  width: 270px;
  position: fixed;
  top: 0;
  line-height: 70px;
  display: block;

  .logo-large {
    display: block;
  }

  .logo-small {
    display: none;
  }
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}

.side-nav .side-nav-link {
  display: block;
  padding: 10px 30px 10px 40px;
  position: relative;
  list-style: none;
  color: white;
  text-decoration: none;
  font-size: 17px;
  outline: none !important;
  .bi {
    margin-right: 0.5rem;
  }

  &.active {
    color: $secondary;
    font-weight: bold;
  }

  &:hover {
    color: $secondary;
  }
}

.side-nav-title {
  padding: 12px 30px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #cedce4;
  font-weight: 700;
  margin-top: 20px;
}

.side-nav-spacer {
  height: 1px;
  width: 60%;
  margin: 0 auto;
  border-top: 1px solid white;
  margin-top: 20px;
}
