.page {
  .page-title-box {
    .page-title {
      font-size: 18px;
      margin: 0;
      line-height: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: inherit;
    }
  }

  .card {
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
    border: none;

    .header-title {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 0.9rem;
      margin-top: 0;
      font-weight: 700;
    }
  }
}
