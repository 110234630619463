.notifications-container {
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;

  .toast {
    .toast-header {
      justify-content: space-between;
      align-items: center;
      .close {
        margin-left: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
