.loader-backdrop {
  z-index: 1050;
  background-color: #000;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.loader-container {
  z-index: 1060;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .spinner-message {
    color: #212529;
    font-size: 2rem;
    font-weight: 700;
  }

  .spinner-border {
    width: 5rem;
    height: 5rem;
    border: 0.5em solid #212529;
    border-right-color: transparent;
  }
}
