footer {
  bottom: 0;
  padding: 15px 15px 15px;
  position: absolute;
  right: 0;
  left: 0;
  text-align: end;
  color: #98a6ad;
  font-size: 0.85em;
}
