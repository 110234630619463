.ssh-keys {
  .generate-key-box {
    resize: none;

    &[readonly] {
      background-color: transparent;
    }
  }

  .import-ssh-key-btn {
    margin-top: 24px;
  }
}
