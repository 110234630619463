.icon-btn-container {
  width: 1.2rem;
  height: 1.2rem;

  &[disabled] {
    cursor: not-allowed;
  }

  &.bold {
    .bi::before {
      font-weight: bold !important;
    }
  }

  .bi-key-fill,
  .bi-play-fill {
    font-size: 1.4rem;
  }

  .icon-btn {
    font-size: 1.2rem;
    color: #98a6ad;

    &:hover {
      color: #5a6166;
    }

    &[disabled] {
      pointer-events: none;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      position: relative;
      top: -3px;
      &:hover path {
        stroke: #5a6166;
      }
      path {
        stroke: #98a6ad;
      }
    }
  }
}
