@import "../../../variables";

.common-table {
  .table {
    thead {
      th {
        border-bottom: 0;
        white-space: nowrap;
      }
      th.actions,
      th.calculatecost,
      th.row-expand {
        width: 1%;
        text-align: center;
      }

      th.sortable {
        padding-right: 30px;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          display: inline-block;
          font-family: bootstrap-icons !important;
          left: auto;
          right: 0.5rem;
          opacity: 0.3;
          font-size: 0.7rem;

          font-style: normal;
          font-weight: normal !important;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
        }
        &::before {
          content: "\f235";
          top: 10px;
        }
        &::after {
          content: "\f229";
          bottom: 10px;
        }

        &.asc::before {
          opacity: 1;
        }
        &.desc::after {
          opacity: 1;
        }
      }
    }
    td.calculatecost,
    td.row-expand {
      width: 1%;
      text-align: center;
    }
    td.actions {
      width: 1%;
      text-align: center;
      white-space: nowrap;

      button:last-child {
        margin-right: 0 !important;
      }
    }

    td {
      vertical-align: middle;
    }

    td.name {
      word-break: break-all;
    }

    tr.selected {
      color: #212529;
      background-color: rgba(0, 0, 0, 0.075);
    }

    tr.clickable {
      cursor: pointer;
    }
  }

  .instance-details-table {
    th.vcpus {
      width: 10%;
    }
    th.memory {
      width: 10%;
    }
    th.instance_profile {
      width: 10%;
    }
  }

  label {
    margin-bottom: 0;
  }

  .table-filter {
    text-align: right;
    .input-group {
      max-width: 600px;
    }
  }

  .table-info {
    background-color: transparent;
  }

  .table-rows {
    text-align: left;

    label {
      display: inline-block;
    }
    select {
      display: inline-block;
      width: auto;
    }
    select.form-control-sm:not([size]):not([multiple]) {
      height: calc(1.5em + 0.56rem + 2px);
    }
  }

  .table-pagination {
    text-align: right;

    ul {
      margin: 2px 0;
      white-space: nowrap;
      justify-content: flex-end;
      display: flex;
      padding-left: 0;
      list-style: none;
    }

    .pagination-item .pagination-link.active {
      z-index: 3;
      color: #fff;
      background-color: $primary;
      border-color: $primary;

      &:hover {
        color: #fff;
        background-color: #3660ad;
        border-color: #335ba3;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .pagination-link {
      padding: 0.375rem 0.75rem;
      position: relative;
      display: block;
      color: initial;
      background-color: transparent;
      border: 1px solid transparent;
      -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      border-radius: 30px !important;
      margin: 0 3px !important;
      border: none;

      &:hover {
        background-color: #e9ecef;
        text-decoration: none;
      }

      &:disabled {
        opacity: 0.3;

        &:hover {
          background-color: inherit;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  @media (max-width: 992px) {
    .table-rows,
    .table-info,
    .table-pagination,
    .table-filter {
      text-align: center;
    }

    .table-pagination ul {
      justify-content: center;
    }

    .table-info,
    .table-rows {
      margin-bottom: 0.5rem;
    }
  }
}
