.card {
  border-radius: 10px;
  .card-header {
    border-radius: 10px 10px 0 0;
  }
}

.modal {
  .modal-content {
    border-radius: 10px;
  }
}

.btn {
  border-radius: 200px;
  padding: 10px 25px 10px 25px;
  text-transform: uppercase;
}

.btn:hover {
  opacity: 0.8;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-sm {
  padding: 8px 18px;
}

.form-control,
select {
  &:focus {
    box-shadow: none !important;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.table-pagination {
  .pagination-link {
    border-radius: 50% !important;
    min-width: 35px;
    &:hover {
      border-color: inherit !important;
      background-color: darken($primary, 15) !important;
      color: white !important;
    }
  }
}

.share-pill {
  padding: 0.35rem 0.6rem;
}

.toast {
  border-radius: 10px;
  opacity: 1;

  .toast-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.4rem 0.75rem;
  }

  .toast-body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
